import React, { useMemo, useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Toast,
  ToastBody,
  ToastHeader,
} from "reactstrap";
import Breadcrumbs from "../../Components/Common/Breadcrumb";
import avatar4 from "../../assets/images/users/avatar-9.jpg";
import moment from "moment";

type PatientDataType = {
  id: string;
  qrcodeid: string;
  primaryDoc: string;
  firstName: string;
  lastName: string;
  avatarUrl: string;
  phone: string;
  email: string;
  gender: string;
  dob: string;
  address: string;
  city: string;
  state: string;
  pincode: string;
};

const PatientProfileEdit = () => {
  document.title = "Patient Management | Dqcare Admin";

  const { id } = useParams();

  const [patientData, setPatientData] = useState<PatientDataType>({
    id: "",
    qrcodeid: "",
    primaryDoc: "",
    firstName: "",
    lastName: "",
    avatarUrl: "",
    phone: "",
    email: "",
    gender: "",
    dob: "",
    address: "",
    city: "",
    state: "",
    pincode: "",
  });
  const [newImage, setNewImage] = useState(null);
  const [toast, setToast] = useState(false);
  const [message, setMessage] = useState("");
  const [preview, setPreview] = useState<null | string>(null);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleSelectFile = () => {
    fileInputRef.current?.click(); // Trigger the hidden file input
  };

  const toggleToast = () => {
    setToast(!toast);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setNewImage(selectedFile);
    const previewURL = URL.createObjectURL(selectedFile);
    setPreview(previewURL);
  };

  const fetchDoctorDetails = async () => {
    try {
      const result = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/get/patient-id/${id}`
      );
      const { data } = await result.json();
      console.log(data);
      setPatientData(data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleNewImage = async (e: any) => {
    e.preventDefault();
    if (!newImage) {
      setMessage("Please select a file.");
      return;
    }

    const formData = new FormData();
    formData.append("avatar", newImage);
    formData.append("id", id as string);

    try {
      setMessage("Uploading New Profile Picture");
      setToast(true);
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/patient/profile`,
        {
          method: "POST",
          body: formData,
          credentials: "include",
        }
      );

      if (response.ok) {
        setMessage("File uploaded successfully.");
      } else {
        const data = await response.json();
        setMessage(`File upload failed`);
      }
      setNewImage(null);
      fetchDoctorDetails();
      setPreview(null);
    } catch (error) {
      console.error("Error uploading file");
      setMessage("An error occurred while uploading the file.");
    }
  };

  useEffect(() => {
    fetchDoctorDetails();
  }, []);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setPatientData({ ...patientData, [name]: value });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    for (let key in patientData) {
      if (patientData.hasOwnProperty(key)) {
        if (key === "dob") {
          formData.append(key, moment(patientData[key]).format("YYYY-MM-DD"));
        } else {
          // Append other properties as is
          formData.append(key, patientData[key]);
        }
      }
    }
    try {
      setMessage("Saving Profile...");
      setToast(true);
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/patient/profile`,
        {
          method: "POST",
          body: formData,
          credentials: "include",
        }
      );
      if (response.ok) {
        setMessage("Profile saved successfully.");
      } else {
        const data = await response.json();
        setMessage(`Failed`);
      }
      fetchDoctorDetails();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <React.Fragment>
      {patientData && (
        <div className="page-content">
          <div
            className="position-fixed top-0 end-0 p-3"
            style={{ zIndex: "1005" }}
          >
            <Toast isOpen={toast}>
              <ToastHeader toggle={toggleToast}>Profile</ToastHeader>
              <ToastBody>{message}</ToastBody>
            </Toast>
          </div>
          <Container fluid={true}>
            <Breadcrumbs
              title="Patient"
              breadcrumbItem="Patient Profile Details"
            />
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <Row className="">
                      <div className="d-flex gap-4">
                        <div className="mt-4 mt-md-0">
                          <img
                            className="rounded-circle avatar-xl"
                            alt="200x200"
                            src={preview || patientData.avatarUrl || avatar4}
                            data-holder-rendered="true"
                          />
                        </div>
                        <div className="flex-grow-1 align-self-center">
                          <div className="text-muted">
                            <h5>{`${patientData.firstName} ${patientData.lastName}`}</h5>
                            <p className="mb-1">{patientData.email}</p>
                            <p className="mb-0">User Id: #{id}</p>
                            <input
                              type="file"
                              name="avatar"
                              accept="image/*"
                              onChange={handleFileChange}
                              ref={fileInputRef}
                              hidden
                            />
                            {newImage ? (
                              <Button
                                color="primary"
                                className="mt-3 btn btn-primary waves-effect waves-light"
                                onClick={handleNewImage}
                              >
                                Upload Profile Picture
                              </Button>
                            ) : (
                              <Button
                                color="primary"
                                outline
                                onClick={handleSelectFile}
                              >
                                Select Profile Picture
                              </Button>
                            )}
                          </div>
                        </div>
                        <div>
                          <div
                            className="d-flex gap-2"
                            style={{ flexDirection: "column" }}
                          >
                            <Link
                              className="btn btn-md btn-primary "
                              to={`/administrator/patient/summary/${id}`}
                              role="button"
                            >
                              Edit Summary
                            </Link>
                            <Link
                              className="btn btn-md btn-primary "
                              to={`/administrator/patient/health/${id}`}
                              role="button"
                            >
                              Health Journey
                            </Link>
                            <Link
                              className="btn btn-md btn-primary"
                              to={`/administrator/patient/reports/${id}`}
                              role="button"
                            >
                              Reports
                            </Link>
                          </div>
                        </div>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <Row className="mb-3">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        First Name
                      </label>
                      <div className="col-md-10">
                        <input
                          name="firstName"
                          value={
                            patientData.firstName ? patientData.firstName : ""
                          }
                          onChange={handleChange}
                          className="form-control"
                          type="text"
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Last Name
                      </label>
                      <div className="col-md-10">
                        <input
                          name="lastName"
                          value={
                            patientData.lastName ? patientData.lastName : ""
                          }
                          onChange={handleChange}
                          className="form-control"
                          type="text"
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label
                        htmlFor="example-tel-input"
                        className="col-md-2 col-form-label"
                      >
                        Phone
                      </label>
                      <div className="col-md-10">
                        <input
                          name="phone"
                          value={patientData.phone ? patientData.phone : ""}
                          onChange={handleChange}
                          className="form-control"
                          type="tel"
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label
                        htmlFor="example-email-input"
                        className="col-md-2 col-form-label"
                      >
                        Email
                      </label>
                      <div className="col-md-10">
                        <input
                          name="email"
                          value={patientData.email ? patientData.email : ""}
                          onChange={handleChange}
                          className="form-control"
                          type="email"
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Gender
                      </label>
                      <div className="col-md-10">
                        <select
                          name="gender"
                          value={patientData.gender ? patientData.gender : ""}
                          onChange={handleChange}
                          className="form-control"
                        >
                          <option value="">Select Gender</option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                          <option value="other">Other</option>
                        </select>
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label
                        htmlFor="example-date-input"
                        className="col-md-2 col-form-label"
                      >
                        Date of Birth
                      </label>
                      <div className="col-md-10">
                        <input
                          name="dob"
                          value={
                            patientData.dob
                              ? moment(patientData.dob).format("YYYY-MM-DD")
                              : ""
                          }
                          onChange={handleChange}
                          className="form-control"
                          type="date"
                          id="example-date-input"
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Address
                      </label>
                      <div className="col-md-10">
                        <input
                          name="address"
                          value={patientData.address ? patientData.address : ""}
                          onChange={handleChange}
                          className="form-control"
                          type="text"
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        City
                      </label>
                      <div className="col-md-10">
                        <input
                          name="city"
                          value={patientData.city ? patientData.city : ""}
                          onChange={handleChange}
                          className="form-control"
                          type="text"
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        State
                      </label>
                      <div className="col-md-10">
                        <input
                          name="state"
                          value={patientData.state ? patientData.state : ""}
                          onChange={handleChange}
                          className="form-control"
                          type="text"
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Pincode
                      </label>
                      <div className="col-md-10">
                        <input
                          name="pincode"
                          value={patientData.pincode ? patientData.pincode : ""}
                          onChange={handleChange}
                          className="form-control"
                          type="text"
                        />
                      </div>
                    </Row>
                    <Row className="mb-3 px-3">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      ></label>
                      <Button
                        color="primary"
                        className="col-md-10 btn btn-primary waves-effect waves-light"
                        onClick={handleSubmit}
                      >
                        Save Profile
                      </Button>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </React.Fragment>
  );
};

export default PatientProfileEdit;
