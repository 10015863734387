import React, { useMemo, useState, useEffect } from 'react'
import Breadcrumbs from '../../Components/Common/Breadcrumb';
import TableContainer from '../../Components/Common/TableContainer';
import Pagination from "Components/Common/Pagination";

const AdministratorDoctor = () => {
  document.title = "Doctor Management | Dqcare Admin";

  const columns = useMemo(
    () => [
        {
            Header: 'Doctor ID',
            accessor: 'id',
            Filter: true,
        },
        {
            Header: 'Specialty',
            accessor: 'specialty',
            Filter: true,
        },{
            Header: 'Email',
            accessor: 'email',
            Filter: true,
        },{
            Header: 'First Name',
            accessor: 'firstName',
            Filter: true,
        },{
            Header: 'Last Name',
            accessor: 'lastName',
            Filter: true,
        },{
            Header: 'Phone No.',
            accessor: 'phone',
            Filter: true,
        },
        {
            Header: 'Gender',
            accessor: 'gender',
            Filter: true,
        },
        {
          Header: 'Options',
          accessor: 'options',
          Filter: true,
        }
    ],
    []
);

const [data, setData] = useState<any>([]);

const fetchAdminData = async() => {
  try {
    const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/get/all-doctors`);
    const { doctor } = await res.json();
    doctor.forEach(element => {
      element['options'] = `${process.env.REACT_APP_PUBLIC_URL}/administrator/doctor/${element['id']}`
    });
    setData(doctor);
  } catch (err) {
    console.log(err);
  }
}

useEffect(() => {
  fetchAdminData();
}, [])

const [currentpages, setCurrentpages] = useState<any>(data);

  return (
    <React.Fragment>
      <div className="page-content">
            <div className="container-fluid">
                <Breadcrumbs title="Tables" breadcrumbItem="Data Tables" />
                <TableContainer
                    columns={columns}
                    data={currentpages}
                    isGlobalFilter={true}
                    isAddOptions={false}
                    customPageSize={10}
                    theadClass="table-light"
                    tableClass="table table-bordered dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                />
                <Pagination
                    perPageData={10}
                    data={data}
                    setCurrentpages={setCurrentpages}
                    currentpages={currentpages}
                />
            </div>
        </div>
    </React.Fragment >
  );
}

export default AdministratorDoctor;