import React, { useMemo, useState, useEffect } from 'react'
import Breadcrumbs from '../../Components/Common/Breadcrumb';
import TableContainer from '../../Components/Common/TableContainer';
import Pagination from "Components/Common/Pagination";
import { useParams } from 'react-router-dom';

const ReportsOfPatient = () => {
  document.title = "Admin Management | Dqcare Admin";
  const {id} = useParams();
  const columns = useMemo(
    () => [
        {
            Header: 'Admin ID',
            accessor: 'ReportId',
            Filter: true,
        },
        {
            Header: 'Email ID',
            accessor: 'ReportDate',
            Filter: true,
        },
        {
          Header: 'Options',
          accessor: 'ReportImageUrl',
          Filter: true,
          Cell: ({ row }) => (
            <a
              href={row.original.ReportImageUrl}
              color="primary"
              className="btn btn-primary waves-effect waves-light"
              
            >
              Download
            </a>
          ),
        },
  
    ],
    []
);

const [data, setData] = useState<any>([]);

const fetchReportsData = async() => {
  try {
    const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/reports/${id}`);
    const { reports } = await res.json();
    console.log(reports);
    setData(reports);
  } catch (err) {
    console.log(err);
  }
}

useEffect(() => {
  fetchReportsData()
}, [data])

const [currentpages, setCurrentpages] = useState<any>(data);

  return (
    <React.Fragment>
      <div className="page-content">
            <div className="container-fluid">
                <Breadcrumbs title="Tables" breadcrumbItem="Data Tables" />
                <TableContainer
                    columns={columns}
                    data={currentpages}
                    isGlobalFilter={true}
                    isAddOptions={false}
                    customPageSize={10}
                    theadClass="table-light"
                    tableClass="table table-bordered dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                />
                <Pagination
                    perPageData={10}
                    data={data}
                    setCurrentpages={setCurrentpages}
                    currentpages={currentpages}
                />
            </div>
        </div>
    </React.Fragment >
  );
}

export default ReportsOfPatient;