import React, { useMemo, useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Container, Modal, Row, Toast, ToastBody, ToastHeader } from "reactstrap";
import Breadcrumbs from "../../Components/Common/Breadcrumb";
import avatar4 from "../../assets/images/users/avatar-9.jpg";
import moment from "moment";

type healthJourneyType = {
  id: string;
  patientId: string;
  title: string;
  description: string;
  created_at: string;
}

type PatientDataType = {
    id: string;
    qrcodeid: string;
    primaryDoc: string;
    firstName: string;
    lastName: string;
    avatarUrl: string;
    phone: string;
    email: string;
    gender: string;
    dob: string;
    address: string;
    city: string;
    state: string;
    pincode: string;
  }

const HealthJourney = () => {
  document.title = "Patient Management | Dqcare Admin";

  const { id } = useParams();

  const [healthJourneyData, setHealthJourneyData] = useState<healthJourneyType[] | null>();
  const [modal_standard, setmodal_standard] = useState(false);
  const [edit, setEdit] = useState(false);
  const [journeyId, setJourneyId] = useState<null | string>(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  function tog_standard(id?: string) {
    setmodal_standard(!modal_standard);
    removeBodyCss();
    if (id !== undefined) {
      setEdit(true);
      setJourneyId(id);
    } else setEdit(false);
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const [toast, setToast] = useState(false);
  const [message, setMessage] = useState("");
  const [patientData, setPatientData] = useState<PatientDataType>({
    id:"",
    qrcodeid:"",
    primaryDoc:"",
    firstName:"",
    lastName:"",
    avatarUrl:"",
    phone:"",
    email:"",
    gender:"",
    dob:"",
    address:"",
    city:"",
    state:"",
    pincode:""
  });

  const fetchHealthJourney = async () => {
    try {
      const result = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/journey/get/${id}`
      );
      const { data } = await result.json();
      console.log(data);
      setHealthJourneyData(data);
    } catch (err) {
      console.log(err);
    }
  };


  const fetchPatientDetails = async () => {
    try {
      const result = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/get/patient-id/${id}`
      );
      const { data } = await result.json();
      console.log(data);
      setPatientData(data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      if (edit) {
        setMessage("Updating Journey");
        setToast(true);
        const formData = new FormData();
        formData.append("journeyId", journeyId as string);
        formData.append("title", title);
        formData.append("description", description);
        const data = {patientId: id, title, description}
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/journey/edit`, {
          method: 'POST',
          body: formData,
          credentials: "include"
        });
        if (response.ok) {
          setMessage('Journey updated successfully.');
        } else {
          const data = await response.json();
          setMessage(`Failed`);
        }
        setTitle("")
        setDescription("")
      } else {
        setMessage("Adding Journey...")
        setToast(true);
        const formData = new FormData();
        formData.append("patientId", id as string);
        formData.append("title", title);
        formData.append("description", description);
        const data = {patientId: id, title, description}
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/journey/add`, {
          method: 'POST',
          body: formData,
          credentials: "include"
        });
        if (response.ok) {
          setMessage('Journey added successfully.');
        } else {
          const data = await response.json();
          setMessage(`Failed`);
        }
      }
        fetchHealthJourney();
    } catch (err) {
      console.log(err);
    }
  }

  const handleDelete = async (id: string) => {
    try {
      setMessage("Deleting Journey");
      setToast(true);
      const formData = new FormData();
      formData.append("journeyId", id);
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/journey/delete`, {
        method: "POST",
        body: formData,
        credentials: "include"
      })
      if (response.ok) {
        setMessage('Journey deleted successfully.');
      } else {
        const data = await response.json();
        setMessage(`Failed`);
      }
      fetchHealthJourney();
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    fetchHealthJourney();
    fetchPatientDetails();
  }, []);

  return (
    <React.Fragment>
        <div className="page-content">
            <Container fluid={true}>
                <Breadcrumbs title="Patient" breadcrumbItem="Patient Health Journey" />
                <Card>
                  <CardBody>
                    <Row className="">
                    <div className="d-flex gap-4" style={{alignItems: "center", justifyContent: "space-between"}}>
                        {patientData.firstName + " " + patientData.lastName}
                        <button
                            type="button"
                            onClick={() => {
                                tog_standard();
                            }}
                            className="btn btn-primary "
                            data-toggle="modal"
                            data-target="#myModal"
                            >
                            Add New Journey
                        </button>
                    </div>
                    </Row>
                  </CardBody>
                </Card>
                <div className=""  style={{display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',gridGap: '10px'}} >
                    {healthJourneyData?.map((item, index) => (
                        <Card key={index}>
                            <CardHeader className="bg-transparent border-bottom text-uppercase d-flex" style={{justifyContent: "space-between"}}>
                              <div className="">{item.title}</div>
                              <div className="d-flex gap-2" style={{justifyContent: "space-between", marginTop: "-10px"}}>                                
                                <button className="btn" style={{ width: "16px", height: "16px" }} onClick={() => (setTitle(item.title), setDescription(item.description), tog_standard(item.id))}>
                                  <i
                                   className="mdi mdi-pencil font-size-16"
                                   style={{color: "gray"}}
                                  ></i>
                                </button>
                                <button className="btn" style={{ width: "16px", height: "16px" }} onClick={() => (handleDelete(item.id))}>
                                  <i
                                   className="mdi mdi-trash-can font-size-16"
                                   style={{color: "red"}}
                                  ></i>
                                </button>
                              </div>
                            </CardHeader>
                            <CardBody>
                                <blockquote className="card-blockquote mb-0">
                                <p>
                                    {item.description}
                                </p>
                                </blockquote>
                            </CardBody>
                        </Card>
                    ))}
                </div>
            </Container>
            <Modal
                isOpen={modal_standard}
                toggle={() => {
                tog_standard();
                }}
            >
                <div className="modal-header">
                <h5 className="modal-title mt-0" id="myModalLabel">
                    Add a new journey
                </h5>
                <button
                    type="button"
                    onClick={() => {
                    setmodal_standard(false);
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div className="modal-body">
                    <Row className="mb-3">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Title
                      </label>
                      <div className="col-md-10">
                        <input
                          name="salutation"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                          className="form-control"
                          type="text"
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Description
                      </label>
                      <div className="col-md-10">
                        <input
                          name="salutation"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                          className="form-control"
                          type="text"
                        />
                      </div>
                    </Row>
                    <Row className="mb-3 px-3">
                    <label
                        htmlFor="example-text-input"
                        className="col-md-2 mr-2 col-form-label"
                      >
                      </label>
                    <Button
                        color="primary"
                        className="col-md-10 btn btn-primary waves-effect waves-light"
                        onClick={handleSubmit}
                      >
                        {edit ? "Update Journey" : "Add Journey"}
                      </Button>
                      </Row>
                </div>
            </Modal>
        </div>
    </React.Fragment>
  );
};

export default HealthJourney;
