import React, { useMemo, useState, useEffect } from 'react'
import Breadcrumbs from '../../Components/Common/Breadcrumb';
import TableContainer from '../../Components/Common/TableContainer';
import Pagination from "Components/Common/Pagination";

const AdministratorAdmin = () => {
  document.title = "Admin Management | Dqcare Admin";

  const columns = useMemo(
    () => [
        {
            Header: 'Admin ID',
            accessor: 'id',
            Filter: true,
        },
        {
            Header: 'Email ID',
            accessor: 'email',
            Filter: true,
        },
        {
          Header: 'Options',
          accessor: 'options',
          Filter: true,
        }
    ],
    []
);

const [data, setData] = useState<any>([]);

const fetchAdminData = async() => {
  try {
    const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/get/admins`);
    const { data } = await res.json();
    setData(data);
  } catch (err) {
    console.log(err);
  }
}

useEffect(() => {
  fetchAdminData();
}, [data])

const [currentpages, setCurrentpages] = useState<any>(data);

  return (
    <React.Fragment>
      <div className="page-content">
            <div className="container-fluid">
                <Breadcrumbs title="Tables" breadcrumbItem="Data Tables" />
                <TableContainer
                    columns={columns}
                    data={currentpages}
                    isGlobalFilter={true}
                    isAddOptions={false}
                    customPageSize={10}
                    theadClass="table-light"
                    tableClass="table table-bordered dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                />
                <Pagination
                    perPageData={10}
                    data={data}
                    setCurrentpages={setCurrentpages}
                    currentpages={currentpages}
                />
            </div>
        </div>
    </React.Fragment >
  );
}

export default AdministratorAdmin;