import React, { useEffect, useState } from "react";
import { useParams , useNavigate } from 'react-router-dom';
import moment from "moment";
import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Container,
  Label,
  Input,
  Toast, ToastBody, ToastHeader
} from "reactstrap"
import {
  Button,
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../Components/Common/Breadcrumb";

const AddSummary = () => {
  const navigate = useNavigate();
 const {id} = useParams();
 const [data, setData] = useState({
  known_case_of: '',           // Provide initial values as empty strings
  family_history: '',
  operative_history: '',
  habit_history: '',
  any_allergy: '',
  current_medications: '',
  vacation_history: '',
  height: '',
  weight: '',
  last_opd_visit: '',  // Provide a default value if needed
  last_admission: '',
  blood_group: 'default',        // Provide a default value if needed
});
const [toast, setToast] = useState(false);
const [message, setMessage] = useState("");
const toggleToast = () => {
  setToast(!toast);
};

 const fetchSummary = async() => {
    try {
      const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/summary/get?id=${id}`);
      const {summary} = await res.json();
      // console.log(summary[0]);
      // console.log(data);
      setData(summary[0]);
    } catch (err) {
      console.log(err);
    }
  }
 console.log(id);
 useEffect(() => {
  fetchSummary();
  // console.log(data);
}, [id])
function formatDate(inputDate : any) {
  const dateObject = new Date(inputDate);

  // Extract the date components (year, month, and day)
  const year = dateObject.getFullYear();
  const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(dateObject.getDate()).padStart(2, '0');

  // Create the formatted string "YYYY-MM-DD"
  const formattedDate = `${year}-${month}-${day}`;
  // console.log(String(formattedDate))
  return String(formattedDate);
}
  //meta title
  const handleChange = (e : any) => {
        const {name,value}= e.target;
        
        if (name === "last_opd_visit" || name === "last_admission") {
          // Format the date value to "yyyy-mm-dd"
          const formattedDate = new Date(value).toISOString().split("T")[0];
          const newDate = moment(formattedDate).format('YYYY-MM-DD')
          // console.log();
         
          setData({
            ...data,
            [name]: newDate,
          });
        } else {
          setData({
            ...data,
            [name]: value,
          });
        }
      
  }
  const handleSubmit = async (e: any) => {
    e.preventDefault()
    try {
      setMessage("Saving Profile...")
      setToast(true);
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/summary/update`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        // Successful response, you can handle it here
        setMessage('Profile saved successfully.');
        console.log('Data successfully submitted');
        navigate(-1);
        // this.props.history.goBack();
      } else {
        // Handle error responses here
        setMessage(`Failed`);
        console.error('Error submitting data');
      }
    } catch (error) {
      // Handle network or other errors here
      console.error('Error:', error);
    }
  }
  document.title = "Form Elements | Skote - React Admin & Dashboard Template"

  return (
    <React.Fragment>
      <div className="page-content">
      <div className="position-fixed top-0 end-0 p-3" style={{ zIndex: "1005" }}>
            <Toast isOpen={toast}>
                <ToastHeader toggle={toggleToast}>
                    Profile
                </ToastHeader>
                <ToastBody>
                    {message}
                </ToastBody>
            </Toast>
          </div>
        <Container fluid={true}>
          <Breadcrumbs title="Patient" breadcrumbItem="Summary" />
    <Row>
    <Col>
      <Card>
        <CardBody>
          <CardTitle className="h4">Edit Summary</CardTitle>

          <Row className="mb-3">
            <label
              htmlFor="example-text-input"
              className="col-md-2 col-form-label"
            >
              Known_case_of
            </label>
            <div className="col-md-10">
              <input
                className="form-control"
                name="known_case_of"
                type="text"
                value={data ? data.known_case_of : ""}
                onChange={handleChange}
              />
            </div>
          </Row>
          <Row className="mb-3">
            <label
              htmlFor="example-text-input"
              className="col-md-2 col-form-label"
            >
              family_history
            </label>
            <div className="col-md-10">
              <input
                className="form-control"
                type="text"
                name = "family_history"
                value={data ? data.family_history : ""}
                onChange={handleChange}
              />
            </div>
          </Row>
          <Row className="mb-3">
            <label
              htmlFor="example-text-input"
              className="col-md-2 col-form-label"
            >
              operative_history
            </label>
            <div className="col-md-10">
              <input
                className="form-control"
                type="text"
                name = "operative_history"
                value={data ? data.operative_history : ""}
                onChange={handleChange}
              />
            </div>
          </Row>
          <Row className="mb-3">
            <label
              htmlFor="example-text-input"
              className="col-md-2 col-form-label"
            >
              habit_history
            </label>
            <div className="col-md-10">
              <input
                className="form-control"
                type="text"
                name = "habit_history"
                value={data ? data.habit_history : ""}
                onChange={handleChange}
              />
            </div>
          </Row>
          <Row className="mb-3">
            <label
              htmlFor="example-text-input"
              className="col-md-2 col-form-label"
            >
              any_allergy
            </label>
            <div className="col-md-10">
              <input
                className="form-control"
                type="text"
                name = "any_allergy"
                value={data ? data.any_allergy : ""}
                onChange = {handleChange}
              />
            </div>
          </Row>
          <Row className="mb-3">
            <label
              htmlFor="example-text-input"
              className="col-md-2 col-form-label"
            >
              current_medications
            </label>
            <div className="col-md-10">
              <input
                className="form-control"
                type="text"
                name = "current_medications"
                value={data ? data.current_medications : ""}
                onChange = {handleChange}
              />
            </div>
          </Row>
          <Row className="mb-3">
            <label
              htmlFor="example-text-input"
              className="col-md-2 col-form-label"
            >
              vacation_history
            </label>
            <div className="col-md-10">
              <input
                className="form-control"
                type="text"
                name = "vacation_history"
                value={data ? data.vacation_history : ""}
                onChange = {handleChange}
              />
            </div>
          </Row>
          <Row className="mb-3">
            <label
              htmlFor="example-number-input"
              className="col-md-2 col-form-label"
            >
              Height
            </label>
            <div className="col-md-10">
              <input
                className="form-control"
                type="text"
                value={data ? data.height : ""}
                name = "height"
                id="example-number-input"
                onChange = {handleChange}
              />
            </div>
          </Row>
          <Row className="mb-3">
            <label
              htmlFor="example-number-input"
              className="col-md-2 col-form-label"
            >
              Weight
            </label>
            <div className="col-md-10">
              <input
                className="form-control"
                type="text"
                value={data ? data.weight : ""}
                id="example-number-input"
                onChange = {handleChange}
                name = "weight"
              />
            </div>
          </Row>
          <Row className="mb-3">
            <label
              htmlFor="example-date-input"
              className="col-md-2 col-form-label"
            >
              last_opd_visit
            </label>
            <div className="col-md-10">
              <input
                className="form-control"
                type="date"
                value={data ? formatDate(data.last_opd_visit) : "2023-04-17"}
                id="example-date-input"
                onChange = {handleChange}
                name = "last_opd_visit"
              />
            </div>
          </Row>
          <Row className="mb-3">
            <label
              htmlFor="example-date-input"
              className="col-md-2 col-form-label"
            >
              last_admission
            </label>
            <div className="col-md-10">
              <input
                className="form-control"
                type="date"
                value={data ? formatDate(data.last_admission) : ""}
                id="example-date-input"
                onChange = {handleChange}
                name = "last_admission"
              />
            </div>
          </Row>
          <Row className="mb-3">
            <label className="col-md-2 col-form-label">Select</label>
            <div className="col-md-10">
              <select className="form-control" value={data ? data.blood_group : "default"} onChange = {handleChange} name = "blood_group">
                <option disabled value = "default">Choose Blood Group</option>
                <option value = "A+">A+</option>
                <option value = "A-">A-</option>
                <option value = "B+">B+</option>
                <option value = "B-">B-</option>
                <option value = "O+">O+</option>
                <option value = "O-">O-</option>
                <option value = "AB+">AB+</option>
                <option value = "AB-">AB-</option>
              </select>
            </div>
          </Row>
        </CardBody>
        <Button
                      color="primary"
                      className="btn btn-primary waves-effect waves-light"
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>
      </Card>
    </Col>
  </Row>
  </Container>
      </div>
    </React.Fragment>
  )
}

export default AddSummary
