import { userForgetPasswordSuccess, userForgetPasswordError } from "./reducer"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";

import {
  postFakeForgetPwd,
  postJwtForgetPwd,
} from "../../../helpers/fakebackend_helper";

const fireBaseBackend = getFirebaseBackend();

export const userForgetPassword = (user, history) => async (dispatch) => {
  try {
      let response;
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {

          response = fireBaseBackend.forgetPassword(
              user.email
          )

      } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
          response = postJwtForgetPwd(
              user.email
          )
      } else if (process.env.REACT_APP_DEFAULTAUTH === "backend") {
        const data = {
            email: user.email
          };
        response = fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/admin/forgot-password`, {
            method: "POST", 
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        });
    } else {
          response = postFakeForgetPwd(
              user.email
          )
      }

      const data = await response;

      if (data) {
          dispatch(userForgetPasswordSuccess(
              "Reset link are sended to your mailbox, check there first"
          ))
      }
  } catch (forgetError) {
      dispatch(userForgetPasswordError("An error occurred. Try again!"))
  }
}