import React from "react";
import { Navigate } from "react-router-dom";
import Cookies from "js-cookie";

const AuthProtected = (props) => {
  const isAdminSessionPresent = Cookies.get('ADMIN-SESSION') || localStorage.getItem("authUser");
  if (!isAdminSessionPresent) {
    return (
      <Navigate to={{ pathname: "/login" }} />
    );
  }
  return (<React.Fragment>
    {props.children}
  </React.Fragment>);
};

export default AuthProtected;
