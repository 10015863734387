const tableData = [
    {

        id: 1,
        symbol: "GOOG",
        name: "Google Inc.",
        price: "597.74",
        time: "12:12PM",
        change: "14.81 (2.54%)",
        prevClose: "582.93",
        open: "597.95",
        bid: "597.73 x 100",
        ask: "597.91 x 300",
        weekRange: "731.10"
    },
    {
        id: 2,
        symbol: "AAPL",
        name: "Apple Inc.",
        price: "378.94",
        time: "12:22PM",
        change: "5.74 (1.54%)",
        prevClose: "373.20",
        open: "381.02",
        bid: "378.92 x 300",
        ask: "378.99 x 100",
        weekRange: "505.94"
    },
    {
        id: 3,
        symbol: "AMZN",
        name: "Amazon.com Inc.",
        price: "191.55",
        time: "12:23PM",
        change: "3.16 (1.68%)",
        prevClose: "188.39",
        open: "194.99",
        bid: "191.52 x 300",
        ask: "191.58 x 100",
        weekRange: "240.32"
    },
    {
        id: 4,
        symbol: "ORCL",
        name: "Oracle Corporation",
        price: "31.15",
        time: "12:44PM",
        change: "1.41 (4.72%)",
        prevClose: "29.74",
        open: "30.67",
        bid: "31.14 x 6500",
        ask: "31.15 x 3200",
        weekRange: "36.11"
    },
    {
        id: 5,
        symbol: "MSFT",
        name: "Microsoft Corporation",
        price: "25.50",
        time: "12:27PM",
        change: "0.66 (2.67%)",
        prevClose: "24.84",
        open: "25.37",
        bid: "25.50 x 71100",
        ask: "25.51 x 17800",
        weekRange: "31.50"
    },
    {
        id: 6,
        symbol: "CSCO",
        name: "Cisco Systems, Inc.",
        price: "18.65",
        time: "12:45PM",
        change: "0.97 (5.49%)",
        prevClose: "17.68",
        open: "18.23",
        bid: "18.65 x 10300",
        ask: "18.66 x 24000",
        weekRange: "21.12"
    },
    {
        id: 7,
        symbol: "YHOO",
        name: "Yahoo! Inc.",
        price: "15.81",
        time: "12:25PM",
        change: "0.11 (0.67%)",
        prevClose: "15.70",
        open: "15.94",
        bid: "15.79 x 6100",
        ask: "15.80 x 17000",
        weekRange: "18.16"
    },
    {
        id: 8,
        symbol: "GOOG",
        name: "Google Inc.",
        price: "597.74",
        time: "12:12PM",
        change: "14.81 (2.54%)",
        prevClose: "582.93",
        open: "597.95",
        bid: "597.73 x 100",
        ask: "597.91 x 300",
        weekRange: "731.10"
    },
    {
        id: 9,
        symbol: "AAPL",
        name: "Apple Inc.",
        price: "378.94",
        time: "12:22PM",
        change: "5.74 (1.54%)",
        prevClose: "373.20",
        open: "381.02",
        bid: "378.92 x 300",
        ask: "378.99 x 100",
        weekRange: "505.94"
    },
    {
        id: 10,
        symbol: "AMZN",
        name: "Amazon.com Inc.",
        price: "191.55",
        time: "12:23PM",
        change: "3.16 (1.68%)",
        prevClose: "188.39",
        open: "194.99",
        bid: "191.52 x 300",
        ask: "191.58 x 100",
        weekRange: "240.32"
    },
    {
        id: 11,
        symbol: "ORCL",
        name: "Oracle Corporation",
        price: "31.15",
        time: "12:44PM",
        change: "1.41 (4.72%)",
        prevClose: "29.74",
        open: "30.67",
        bid: "31.14 x 6500",
        ask: "31.15 x 3200",
        weekRange: "36.11"
    },
    {
        id: 12,
        symbol: "MSFT",
        name: "Microsoft Corporation",
        price: "25.50",
        time: "12:27PM",
        change: "0.66 (2.67%)",
        prevClose: "24.84",
        open: "25.37",
        bid: "25.50 x 71100",
        ask: "25.51 x 17800",
        weekRange: "31.50"
    },
    {
        id: 13,
        symbol: "CSCO",
        name: "Cisco Systems, Inc.",
        price: "18.65",
        time: "12:45PM",
        change: "0.97 (5.49%)",
        prevClose: "17.68",
        open: "18.23",
        bid: "18.65 x 10300",
        ask: "18.66 x 24000",
        weekRange: "21.12"
    },
    {
        id: 14,
        symbol: "YHOO",
        name: "Yahoo! Inc.",
        price: "15.81",
        time: "12:25PM",
        change: "0.11 (0.67%)",
        prevClose: "15.70",
        open: "15.94",
        bid: "15.79 x 6100",
        ask: "15.80 x 17000",
        weekRange: "18.16"
    },
    {
        id: 15,
        symbol: "GOOG",
        name: "Google Inc.",
        price: "597.74",
        time: "12:12PM",
        change: "14.81 (2.54%)",
        prevClose: "582.93",
        open: "597.95",
        bid: "597.73 x 100",
        ask: "597.91 x 300",
        weekRange: "731.10"
    },
    {
        id: 16,
        symbol: "AAPL",
        name: "Apple Inc.",
        price: "378.94",
        time: "12:22PM",
        change: "5.74 (1.54%)",
        prevClose: "373.20",
        open: "381.02",
        bid: "378.92 x 300",
        ask: "378.99 x 100",
        weekRange: "505.94"
    },
    {
        id: 17,
        symbol: "AMZN",
        name: "Amazon.com Inc.",
        price: "191.55",
        time: "12:23PM",
        change: "3.16 (1.68%)",
        prevClose: "188.39",
        open: "194.99",
        bid: "191.52 x 300",
        ask: "191.58 x 100",
        weekRange: "240.32"
    },
    {
        id: 18,
        symbol: "ORCL",
        name: "Oracle Corporation",
        price: "31.15",
        time: "12:44PM",
        change: "1.41 (4.72%)",
        prevClose: "29.74",
        open: "30.67",
        bid: "31.14 x 6500",
        ask: "31.15 x 3200",
        weekRange: "36.11"
    },
    {
        id: 19,
        symbol: "MSFT",
        name: "Microsoft Corporation",
        price: "25.50",
        time: "12:27PM",
        change: "0.66 (2.67%)",
        prevClose: "24.84",
        open: "25.37",
        bid: "25.50 x 71100",
        ask: "25.51 x 17800",
        weekRange: "31.50"
    },
    {
        id: 20,
        symbol: "CSCO",
        name: "Cisco Systems, Inc.",
        price: "18.65",
        time: "12:45PM",
        change: "0.97 (5.49%)",
        prevClose: "17.68",
        open: "18.23",
        bid: "18.65 x 10300",
        ask: "18.66 x 24000",
        weekRange: "21.12"
    },
    {
        id: 21,
        symbol: "YHOO",
        name: "Yahoo! Inc.",
        price: "15.81",
        time: "12:25PM",
        change: "0.11 (0.67%)",
        prevClose: "15.70",
        open: "15.94",
        bid: "15.79 x 6100",
        ask: "15.80 x 17000",
        weekRange: "18.16"
    },
    {
        id: 22,
        symbol: "GOOG",
        name: "Google Inc.",
        price: "597.74",
        time: "12:12PM",
        change: "14.81 (2.54%)",
        prevClose: "582.93",
        open: "597.95",
        bid: "597.73 x 100",
        ask: "597.91 x 300",
        weekRange: "731.10"
    },
    {
        id: 23,
        symbol: "AAPL",
        name: "Apple Inc.",
        price: "378.94",
        time: "12:22PM",
        change: "5.74 (1.54%)",
        prevClose: "373.20",
        open: "381.02",
        bid: "378.92 x 300",
        ask: "378.99 x 100",
        weekRange: "505.94"
    },
    {
        id: 24,
        symbol: "AMZN",
        name: "Amazon.com Inc.",
        price: "191.55",
        time: "12:23PM",
        change: "3.16 (1.68%)",
        prevClose: "188.39",
        open: "194.99",
        bid: "191.52 x 300",
        ask: "191.58 x 100",
        weekRange: "240.32"
    },
    {
        id: 25,
        symbol: "ORCL",
        name: "Oracle Corporation",
        price: "31.15",
        time: "12:44PM",
        change: "1.41 (4.72%)",
        prevClose: "29.74",
        open: "30.67",
        bid: "31.14 x 6500",
        ask: "31.15 x 3200",
        weekRange: "36.11"
    },
    {
        id: 26,
        symbol: "MSFT",
        name: "Microsoft Corporation",
        price: "25.50",
        time: "12:27PM",
        change: "0.66 (2.67%)",
        prevClose: "24.84",
        open: "25.37",
        bid: "25.50 x 71100",
        ask: "25.51 x 17800",
        weekRange: "31.50"
    },
    {
        id: 27,
        symbol: "CSCO",
        name: "Cisco Systems, Inc.",
        price: "18.65",
        time: "12:45PM",
        change: "0.97 (5.49%)",
        prevClose: "17.68",
        open: "18.23",
        bid: "18.65 x 10300",
        ask: "18.66 x 24000",
        weekRange: "21.12"
    },
    {
        id: 28,
        symbol: "YHOO",
        name: "Yahoo! Inc.",
        price: "15.81",
        time: "12:25PM",
        change: "0.11 (0.67%)",
        prevClose: "15.70",
        open: "15.94",
        bid: "15.79 x 6100",
        ask: "15.80 x 17000",
        weekRange: "18.16"
    },
    {
        id: 29,
        symbol: "GOOG",
        name: "Google Inc.",
        price: "597.74",
        time: "12:12PM",
        change: "14.81 (2.54%)",
        prevClose: "582.93",
        open: "597.95",
        bid: "597.73 x 100",
        ask: "597.91 x 300",
        weekRange: "731.10"
    },
    {
        id: 30,
        symbol: "AAPL",
        name: "Apple Inc.",
        price: "378.94",
        time: "12:22PM",
        change: "5.74 (1.54%)",
        prevClose: "373.20",
        open: "381.02",
        bid: "378.92 x 300",
        ask: "378.99 x 100",
        weekRange: "505.94"
    },
    {
        id: 31,
        symbol: "AMZN",
        name: "Amazon.com Inc.",
        price: "191.55",
        time: "12:23PM",
        change: "3.16 (1.68%)",
        prevClose: "188.39",
        open: "194.99",
        bid: "191.52 x 300",
        ask: "191.58 x 100",
        weekRange: "240.32"
    },
    {
        id: 32,
        symbol: "ORCL",
        name: "Oracle Corporation",
        price: "31.15",
        time: "12:44PM",
        change: "1.41 (4.72%)",
        prevClose: "29.74",
        open: "30.67",
        bid: "31.14 x 6500",
        ask: "31.15 x 3200",
        weekRange: "36.11"
    },
    {
        id: 33,
        symbol: "MSFT",
        name: "Microsoft Corporation",
        price: "25.50",
        time: "12:27PM",
        change: "0.66 (2.67%)",
        prevClose: "24.84",
        open: "25.37",
        bid: "25.50 x 71100",
        ask: "25.51 x 17800",
        weekRange: "31.50"
    },
    {
        id: 34,
        symbol: "CSCO",
        name: "Cisco Systems, Inc.",
        price: "18.65",
        time: "12:45PM",
        change: "0.97 (5.49%)",
        prevClose: "17.68",
        open: "18.23",
        bid: "18.65 x 10300",
        ask: "18.66 x 24000",
        weekRange: "21.12"
    },
    {
        id: 35,
        symbol: "YHOO",
        name: "Yahoo! Inc.",
        price: "15.81",
        time: "12:25PM",
        change: "0.11 (0.67%)",
        prevClose: "15.70",
        open: "15.94",
        bid: "15.79 x 6100",
        ask: "15.80 x 17000",
        weekRange: "18.16"
    }
]

export default tableData;