import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import withRouter from "Components/Common/withRouter";

//Import Breadcrumb
import Breadcrumb from "Components/Common/Breadcrumb";

import avatar from "../../assets/images/users/avatar-9.jpg";

import { editProfile, resetProfileFlag } from "slices/thunk";
import { createSelector } from 'reselect';

const UserProfile = () => {

  //meta title
  document.title = "Profile | Dqcare Admin";

  const dispatch = useDispatch<any>();

  const [email, setemail] = useState("");
  const [name, setname] = useState(null);
  const [idx, setidx] = useState(1);

  const selectProperties = createSelector(
    (state: any) => state.Profile,
    (profile) => ({
      error: profile.error,
      success: profile.success
    })
  );


  const { error, success } = useSelector(selectProperties);

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser") || "{}");
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        setname(obj.displayName);
        setemail(obj.email);
        setidx(obj.uid);
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        setname(obj.username || obj.providerData.displayName);
        setemail(obj.email || obj.providerData.email);
        setidx(obj.uid || obj.providerData.uid);
      } else if (process.env.REACT_APP_DEFAULTAUTH === "backend") {
        setemail(obj?.data?.email as string);
        setidx(obj?.data?.id);
      }
      setTimeout(() => {
        dispatch(resetProfileFlag());
      }, 3000);
    }
  }, [dispatch, success]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: email || "",
      oldPassword: "",
      newPassword: "",
      verifyPassword: ""
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string().required("Please Enter Your Old Password"),
      newPassword: Yup.string().required("Please Enter Your New Password"),
      verifyPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required("Please Enter Your New Password Again")
    }),
    onSubmit: (values) => {
      dispatch(editProfile(values));
    }
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Skote" breadcrumbItem="Profile" />

          <Row>
            <Col lg="12">
              {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? <Alert color="success">{success}</Alert> : null}

              <Card>
                <CardBody>
                  <div className="d-flex gap-2">
                    <div className="ms-3">
                      <img
                        src={avatar}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5>{name}</h5>
                        <p className="mb-1">{email}</p>
                        <p className="mb-0">User Id: #{idx}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className="card-title mb-4">Change Password</h4>

          <Card>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <div className="mb-3">
                  <Label className="form-label">Old password</Label>
                  <Input
                    name="oldPassword"
                    // value={name}
                    className="form-control"
                    placeholder="Enter Old Password"
                    type="password"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.oldPassword || ""}
                    invalid={
                      validation.touched.oldPassword && validation.errors.oldPassword ? true : false
                    }
                  />
                  {validation.touched.oldPassword && validation.errors.oldPassword ? (
                    <FormFeedback type="invalid">{validation.errors.oldPassword}</FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label className="form-label">New password</Label>
                  <Input
                    name="newPassword"
                    // value={name}
                    className="form-control"
                    placeholder="Enter New Password"
                    type="password"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.newPassword || ""}
                    invalid={
                      validation.touched.newPassword && validation.errors.newPassword ? true : false
                    }
                  />
                  {validation.touched.newPassword && validation.errors.newPassword ? (
                    <FormFeedback type="invalid">{validation.errors.newPassword}</FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label className="form-label">Verify password</Label>
                  <Input
                    name="verifyPassword"
                    // value={name}
                    className="form-control"
                    placeholder="Enter New Password Again"
                    type="password"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.verifyPassword || ""}
                    invalid={
                      validation.touched.verifyPassword && validation.errors.verifyPassword ? true : false
                    }
                  />
                  {validation.touched.verifyPassword && validation.errors.verifyPassword ? (
                    <FormFeedback type="invalid">{validation.errors.verifyPassword}</FormFeedback>
                  ) : null}
                </div>
                <div className="mt-4">
                  <Button type="submit" color="danger">
                    Update Password
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);