//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import { postFakeProfile, postJwtProfile } from "../../../helpers/fakebackend_helper";

// action
import { profileSuccess, profileError, resetProfileFlagChange } from "./reducer";


export const editProfile = (user: any) => async (dispatch: any) => {
    try {
        let response: any;
        if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
            const fireBaseBackend = getFirebaseBackend();
            response = fireBaseBackend.editProfileAPI(
                user.username,
                user.idx
            );
        } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
            response = postJwtProfile(
                {
                    username: user.username,
                    idx: user.idx,
                }
            );

        } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
            response = postFakeProfile(user);
        } else if (process.env.REACT_APP_DEFAULTAUTH === "backend") {
            const data = {
                email: user.email,
                oldPassword: user.oldPassword,
                newPassword: user.newPassword
              };
            response = fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/admin/change-password`, {
                method: "POST", 
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data)
            });
        }
        const data = await response;
        if (data) {
            dispatch(profileSuccess("Profile Successfully Updated"));
        }

    } catch (error) {
        dispatch(profileError("An error occurred. Try again!"));
    }
};

export const resetProfileFlag = () => {
    try {
        const response = resetProfileFlagChange();
        return response;
    } catch (error) {
        return error;
    }
};